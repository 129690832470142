@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-color: #1e88e5;
  --danger-color: #f02a2a;
  --success-color: #28a745;
}

html{
  height: 100%;
  margin: 0;
}

* {
  box-sizing: border-box;
}


#root{
  margin: 0;
  padding: 0;
  border: none;
}

.tw-dark body {
  background-image: linear-gradient(to bottom, #2A324B, #232A3B, #1D2230, #181B26, #141619);
}

body {
  background-color: #ffffff;
  color: #333;
  font-family: 'Roboto', sans-serif;
  height: 100%;
  margin: 0;
}

/* for resizeobserver error */
iframe#webpack-dev-server-client-overlay {
  display: none !important;
}

.btn-label {
  font-size: 0.8em;
  text-transform: uppercase;
  font-weight: bold;
  margin-left: 1em;
}
/* UTILS */
.main-container {
  width: 98%;
  margin: 0 auto;
  max-width: 1536px;
}

.nav-links {
  font-size: 0.9em;
}

.nav-links.btn-small {
  text-transform: capitalize;
}

select {
  font-size: 0.9em;
}
/* END OF UTILS */

/* OVER WRITE MATERIALIZE CSS */

/* Side Nav */
.sidenav li.active,
.sidenav li.active:focus {
  background-color: #0000001a;
  color: 000;
}

/* Chip */
a.chip.active,
a.chip:active,
a.chip:focus,
a.chip:focus > span.grey-text,
a.chip.active > span.grey-text,
a.chip.active:active > span.grey-text a.chip.active:focus > span.grey-text {
  background-color: var(--primary-color);
  color: white !important;
  transition: ease 0.5s;
}

a.chip.filter.active,
a.chip.filter.active > span.grey-text,
a.chip.filter:active,
a.chip.filter:focus,
a.chip.filter:active > span.grey-text,
a.chip.filter:focus > span.grey-text {
  background-color: var(--primary-color) !important;
  color: white !important;
  transition: ease 0.5s;
}

a.chip.filter.green-text.active,
a.chip.filter.green-text.active > span.grey-text,
a.chip.filter.green-text:active,
a.chip.filter.green-text:focus,
a.chip.filter.green-text:active > span.grey-text,
a.chip.filter.green-text:focus > span.grey-text {
  background-color: #388e3c !important;
  color: white !important;
  transition: ease 0.5s;
}

a.chip.filter.orange-text.active,
a.chip.filter.orange-text.active > span.grey-text,
a.chip.filter.orange-text:active,
a.chip.filter.orange-text:focus,
a.chip.filter.orange-text:active > span.grey-text,
a.chip.filter.orange-text:focus > span.grey-text {
  background-color: #f57c00 !important;
  color: white !important;
  transition: ease 0.5s;
}

a.chip.filter.grey-text.active,
a.chip.filter.grey-text.active > span.grey-text,
a.chip.filter.grey-text:active,
a.chip.filter.grey-text:focus,
a.chip.filter.grey-text:active > span.grey-text,
a.chip.filter.grey-text:focus > span.grey-text {
  background-color: #616161 !important;
  color: white !important;
  transition: ease 0.5s;
}

/* Btn */
.btn-flat:hover {
  background-color: white !important;
}

/* Input Text boxes */
input[type='text'] {
  font-size: 0.9em !important;
}

input[type='text']:focus {
  border-color: var(--primary-color) !important;
}

input[type='password']:focus {
  border-color: var(--primary-color) !important;
}

input[type='email']:focus {
  border-color: var(--primary-color) !important;
}

/* Radio button */
[type='radio']:checked + span,
[type='radio']:checked + span:before,
[type='radio']:checked + span:after,
[type='radio'].with-gap:checked + span,
[type='ra'].with-gap:checked + span:before,
[type='ra'].with-gap:checked + span:after {
  border: '2px solid' !important;
  border-color: var(--primary-color) !important;
}

[type='radio']:checked + span:after,
[type='ra'].with-gap:checked + span:after {
  background-color: var(--primary-color) !important;
}

/* Text Area */
textarea:focus {
  border-color: var(--primary-color) !important;
}

.materialize-textarea {
  font-size: 0.9em !important;
}

textarea {
  width: 100%;
  height: auto !important;
  overflow-y: visible !important; /* Ensure overflow shows */
  resize: none !important; /* Disable manual resize if not needed */
}


/* Tab */
.tab a {
  text-transform: capitalize !important;
  font-size: 0.85em !important;
  font-weight: bold;
}

.tab .active {
  background-color: #e4f3ff !important;
  font-weight: bold;
}

.tabs .indicator {
  background-color: var(--primary-color) !important;
}

/* Collecton */
.collection-item {
  padding-bottom: 2em !important;
}

/* END OF OVER WRITE */

/* BUTTON STYLES */
#createBtnContainer {
  height: 100%;
  align-items: center;
  justify-content: center;
}
/* END OF BUTTON STYLES */

/* TICKET STYLES */
#ticketContainer {
  padding: 0;
  margin: 0;
}

#ticket-header {
  font-size: 1em;
  font-weight: 500;
  text-transform: uppercase;
}

.ticket-links {
  padding: 1em;
  margin: 0;
}

.ticket-actions-container {
  padding: 0.5em 0 0 0;
}

.ticket-actions-header {
  padding-top: 0.5em;
}

.ticket-actions-label {
  font-size: 0.8em;
  margin-left: 0.5em;
}

.ticket-label,
.section-label {
  font-size: 0.75em;
  color: gray;
}

.section-label {
  font-weight: 500;
}

.ticket-actions-header,
.ticket-info-header,
.ticket-details,
.ticket-desc {
  font-size: 0.95em;
}

.ticket-details,
.ticket-desc {
  font-size: 0.9em;
}

.ticket-actions-header,
.ticket-info-header {
  font-weight: bold;
}

.ticket-info {
  font-size: 0.9em;
}

#ticket-id {
  font-size: 0.8em;
  margin-right: 1em;
}

#ticket-title {
  font-size: 1em;
  font-weight: bold;
  margin-right: 0.5em;
}

/* END OF TICKET STYLES /*

/* COMMENTS STYLES */
.comment-container {
  border: none;
}

.comment-add-container {
  border: none;
  padding: 0 !important;
}

.comment-message {
  font-size: 0.9em;
}

.comment-user {
  font-size: 1em;
  font-weight: bold;
}

.comment-label {
  font-size: 0.8em;
}

.comment-time,
.comment-user-type {
  font-size: 0.75em;
}

.comment-user-type {
  padding: 0.2em 1em;
}

.comment-submit {
  font-size: 2em;
}
/* END OF COMMENTS STYLES */

.alert {
  padding: 0.7rem;
  margin: 1rem 0;
  /* opacity: 0.9; */
  background: var(--light-color);
  color: #333;
}

.alert-badge {
  border-radius: 50%;
  width: 12px;
  height: 12px;
  display: inline-block;
}

.priority-badge {
  margin: auto;
}

.alert-primary {
  background: var(--primary-color);
  color: #fff;
}

.btn-danger,
.bg-danger,
.badge-danger,
.alert-danger {
  position: fixed;
  right: 0;
  top: 0;
  background: var(--danger-color);
  color: #fff;
  border-radius: 5px;
  z-index: 1500;
}

.btn-success,
.bg-success,
.badge-success,
.alert-success {
  position: fixed;
  top: 0;
  right: 0;
  background: var(--success-color);
  color: #fff;
  border-radius: 5px;
  z-index: 1500;
}

@media (max-width: 768px) {
  .chart-container {
    display: block;
  }
  .chart {
    width: 100%;
    margin-bottom: 20px;
  }
  .issue-distribution-graph {
    width: 100%;
    height: 700px;
  }
}

@media (min-width: 769px) {
  .chart-container {
    display: flex;
    /* justify-content: space-between; */
  }
  .chart {
    /* flex: 0 0 calc(50% - 20px); */
    width: 100%;
  }
  .issue-distribution-graph {
    width: 50%;
    height: 700px;
  }
}

/* analytics dashboard */

.heading-box {
  background-color: #f0f0f0;
  padding: 20px;
  width: 100%;
  margin: 10px 0;
  border-radius: 10px;
}

.status-heading {
  font-size: 1.5em;
  font-weight: 700;
  text-align: center;
}

.container-div {
  width: 100%;
  margin: 0 auto;
  padding: 10px;
  position: relative;
  min-height: 250px;
  display: grid;
  /* grid-template-areas:
    'total-capacity count-of-sites'
    'average-kwh average-deviation'
    'avg-days-soiling avg-soiling-loss'
    'mismatch-loss avg-up-time'; */
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.site-details-main-div{
  width: 74%;
  margin: 0 auto;
  /* padding: 10px; */
  position: relative;
  min-height: 100px;
  /* display: grid; */
  /* grid-template-areas:
    'total-capacity count-of-sites'
    'average-kwh average-deviation'
    'avg-days-soiling avg-soiling-loss'
    'mismatch-loss avg-up-time'; */
  /* grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); */
  gap: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.line-box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  width: 100%;
}

.box {
  flex: 0 0 calc(50% - 10px);
  /* background-color: rgb(229, 228, 226); */
  /* padding: 10px;  */
  /* border: 1px solid #ccc; */
  box-sizing: border-box;
  width: 100%;
  /* height: 60px; */
  /* border-radius: 10px; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter-div {
  display: flex;
  align-items: center;
}

@media (max-width: 768px) {
  .container-div {
    /* grid-template-areas:
    'total-capacity'
    'count-of-sites'
    'average-kwh'
    'average-deviation'
    'avg-days-soiling'
    'avg-soiling-loss'
    'mismatch-loss'
    'avg-up-time'; */
    
    grid-template-columns: repeat(3, 1fr);
    gap: 12px;
  }
  .site-details-main-div{
    width: 100%;
    grid-template-columns: repeat(1, 1fr);
    gap: 0;
  }

  .line-box {
    flex-direction: column;
    margin-bottom: 0;
  }
  .box {
    flex: 1 0 auto;
    margin-bottom: 10px;
  }
  /* .box-even {
    background-color: rgb(229, 228, 226);
  }
  .box-odd {
    background-color: rgb(255, 255, 255);
  } */
  .filter-div {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
}

/* issues table */
.pagination-container {
  /* display: flex;
  justify-content: center; */
  /* margin: 0 20px; */
  padding: 10px 0;
  position: sticky;
  bottom: 0;
  z-index: 1;
}
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.pagination button {
  border-radius: 15px;
  background-color: white;
  cursor: pointer;
}

.pagination button.active {
  font-weight: bold;
  color: white;
  background-color: #2a6ff0;
}

.table-container {
  margin-top: 20px;
  max-height: 600px;
  overflow-y: auto;
}

@media screen and (max-width: 768px) {
  .table-container {
    max-height: fit-content;
  }
}

@media screen and (max-width: 992px) {
  .header-tr {
    height: 500px;
  }
  .inverter-tr {
    padding-top: 10px;
  }
  .percDev-tr {
    padding-top: 50px;
  }
  .trend-tr {
    padding-top: 50px;
  }
  .action-tr {
    padding-top: 50px;
  }
  .sps-tr {
    padding-top: 50px;
  }
  .projLoss-tr {
    padding-top: 55px;
  }
  .lastPercDev-tr {
    padding-top: 10px;
  }
  .daysCommLoss-tr {
    padding-top: 10px;
  }
  .status-tr {
    padding-top: 8px;
  }
}

.issues-header {
  position: sticky;
  top: 0;
  z-index: 1;
}

/* Customize the scrollbar track */
.table-container::-webkit-scrollbar {
  width: 5px; /* Width of the scrollbar */
  /* scrollbar-width: thin; */
}

/* Customize the scrollbar thumb (the draggable part) */
.table-container::-webkit-scrollbar-thumb {
  background: blue; /* Background color of the thumb */
  border-radius: 5px; /* Rounded corners for the thumb */
}

/* Customize the scrollbar track on hover */
.table-container::-webkit-scrollbar-track:hover {
  background: #ccc; /* Background color when hovered */
}

/* analytics */

/* Styles for large screens */
.filter-container {
  margin: 5px 0;
  padding: 5px;
  display: flex;
  justify-content: space-around;
}

.DashFilter {
  width: 23%; /* Adjust the width as needed */
  /* background-color: yellowgreen; */
  /* margin: 5px; */
  padding: 3px 10px;
  text-align: center;
  /* display: flex;
  justify-content: center;
  flex-direction: column; */
}

/* Media query for mobile screens */
@media screen and (max-width: 768px) {
  .filter-container {
    flex-wrap: wrap;
  }

  .DashFilter {
    width: 46%; /* Adjust the width to make two filters fit in one line on mobile screens */
  }
}

.custom-datepicker-container .react-datepicker__input-container input {
  /* border: 1px solid red; */
  border: none;
  text-align: center;
  margin: 0;
  cursor: pointer;
  width: 100%;
  background-color: transparent;
  color: black;
  font-weight: bold;
}


.tw-dark .custom-datepicker-container .react-datepicker__input-container input {
  color: white; 
}

.custom-datepicker-container
  .react-datepicker__input-container
  input::placeholder {
  color: black;
}

.tw-dark .custom-datepicker-container
  .react-datepicker__input-container
  input::placeholder {
  color: white;
}

.custom-datepicker-container .react-datepicker-ignore-onclickoutside {
  width: 100%;
}


.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  /* display: block; */
  width: 100%;
  font-size: medium;
  /* font-weight: bold; */
}

.analytics-button {
  background-color: transparent;
  color: black;
  padding: 10px 15px;
  border: none;
  font-weight: 700;
  cursor: pointer;
  margin-right: 5px; /* Add spacing between buttons */
  border-radius: 5px;
  /* border: 1px solid black; */
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.tw-dark .analytics-button {
  background-color: transparent;
  color: white;
  padding: 10px 15px;
  border: none;
  font-weight: 700;
  cursor: pointer;
  margin-right: 5px; /* Add spacing between buttons */
  border-radius: 5px;
  /* border: 1px solid black; */
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.analytics-button.clicked {
  background-color: transparent; /* Change to your desired color */
  border: none;
  border-bottom: 2px solid #ffd232;
}

.loading-spinner {
  position: absolute; /* Absolute positioning for the spinner */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.6); /* Semi-transparent white background */
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-spinner-topIssues {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.6);
  align-items: center;
  z-index: 1; /* Ensure the spinner is above the table content */
}

.loading-spinner-dashboard {
  margin: 5px 0;
  min-height: 100%;
  font-weight: bolder;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-column: span 2;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

@media (max-width: 768px) {
  .loading-spinner-topIssues {
    position: absolute; /* Absolute positioning for the spinner */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .loading-spinner-dashboard {
    min-height: 100%;
  }
}

.ticket-link {
  /* Styles for the non-hover state go here */
  color: blue;
  text-decoration: none;
  font-weight: normal;
  transition: color 0.3s ease, text-decoration 0.3s ease, font-weight 0.3s ease;
}

.ticket-link:hover {
  /* Styles for the hover state go here */
  text-decoration: underline;
  font-weight: bold;
}

/* email preview */

/* Add this CSS in your stylesheets or CSS file */
.email-preview-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.email-preview-content {
  position: relative; /* Make it a positioning context for absolute positioning */
  width: 100%;
  background-color: white;
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  border-radius: 100px;
  border: 2px solid red;
  background-color: white;
}

.site-details-tiles {
  background-color: gray;
  color: white;
  border-radius: 5px;
  padding: 10px;
  width: 20%;
  height: 100px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.site-details-div {
  display: flex;
  justify-content: center;
  gap: 50px;
  padding: 5px;
}

.site-info-div {
  background-color: rgb(229, 228, 226);
  border: 1px solid #ccc;
  width: 74%;
  margin: 10px auto;
  padding: 5px;
  color: black;
}

@media (max-width: 768px) {
  .site-details-div {
    display: flex;
    align-items: center;
    padding: 5px;
    flex-direction: column;
    gap: 10px;
  }
  .site-details-tiles {
    width: 70%;
    height: fit-content;
  }
  .site-info-div {
    width: 100%;
  }
}

.energy-graph-container {
  /* Default styles for larger screens */
  width: 800px;
  max-width: 100%;
  height: 550px;
  max-height: fit-content;
  padding: 15px;
}

@media (max-width: 768px) {
  /* Styles for screens with a maximum width of 768px or smaller */
  .energy-graph-container {
    width: 100%;
    max-width: 100%;
    height: 500px;
    max-height: fit-content;
    padding:0 0 35px 0;
  }
}

.graph-top-div {
  display: flex;
  margin: 20px 0;
  padding: 10px;
  border-radius: 10px;
  flex-direction: column;
  align-items: center;
  height: 820px;
  margin-top: 5px;
}

@media (max-width: 768px) {
  .graph-top-div {
    display: flex;
    margin: 20px 0;
    padding: 10px 0;
    border-radius: 10px;
    flex-direction: column;
    align-items: center;
    margin-top: 5px;
    height: 750px;
  }
}

.react-datepicker-popper{
  z-index: 1000 !important;
}

.legend {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  z-index: 1000; 
}



.legend-item {
  display: flex;
  gap: 5px;
  align-items: center;
}

.geo-filter{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.geo-filter-row{
  display: flex;
}
.geo-filter-column {
  display: flex;
  flex-direction: column;
}

@media (max-width: 768px) {
  .geo-filter{
    flex-direction: column;
  }
  .geo-filter-row{
   justify-content: center;
  }

}

.mismatch-div{
  padding: 10px;
  border-radius: 10px;
  width: 70%;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .mismatch-div{
    width: 100%;
    /* margin: 0; */
  }
}


.inverter-params-button{
  display: flex;
  justify-content: center;
  gap: 10px;
  width: 60%;
}

@media (max-width: 768px) {
  .inverter-params-button{
    width: 100%;
  }
}

.ticket-filter{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .ticket-filter{
    flex-direction: column;
    gap: 10px;
  }
}

.tickets-page-filter{
  display: flex;
}

@media (max-width: 768px) {
  .tickets-page-filter{
    flex-direction: column;
    align-items: center;
  }
}

.bannerInput{
  border: 2px solid black;
  border-radius: 10px;
  padding: 5px;
}

.bannerInput::-webkit-file-upload-button{
  background-color: blue;
  color: white;
  padding: 8px 16px;
  border-radius: 10px;
  cursor: pointer;
  border: 1px solid blue;
}

.historical-filter-div{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.historical-data-div{
  background-color: rgb(229, 228, 226);
  border-radius: 5px;
  width: 30%;
}

.historical-data-top-div{
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 10px 0;
  justify-content: flex-start;
}

@media (max-width: 576px) {
  .historical-filter-div{
    flex-direction: column;
    align-items: center;
  }
  .historical-data-div{
    width: 90%;
  }
  .historical-data-top-div{
    justify-content: center;
  }
}

.maintenance-images-top-box {
  display: flex;
  gap: 10px;
}

.maintenance-images-div {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 30%;
  align-items: center;
  height: 210px;
  border: 1px solid black;
  padding: 0 5px;
}

@media (max-width: 600px) {
  .maintenance-images-top-box{
    flex-direction: column;
  }
  .maintenance-images-div {
    width: 100%;
  }
}

.mainScreenInfo{
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

@media (max-width: 600px) {
  .mainScreenInfo{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

.active-generation-tables{
  display: flex;
  gap: 5px;
}

.generation-graphs{
  display: flex;
  justify-content: center;
  padding: 10px;
  margin: 50px 0;
}

.plant-details-table-th{
  text-align: center;
  border: 1px solid black;
  width: fit-content;
  min-width: 100px;
}

.plant-details-table-td{
  text-align: center;
  border: 1px solid black;
  width: fit-content;
  min-width: 100px;
}

.individual-page-options{
  display: flex;
  justify-content: space-around;
  width: 100%;
}

@media (max-width: 600px) {
  .active-generation-tables{
    flex-direction: column;
  }
  .generation-graphs{
    flex-direction: column;
    padding: 0;
    gap: 35px;
  }
  .individual-page-options{
   justify-content: flex-start;
  }
}

.commLoss-mail-div{
  background-color: rgba(221, 221, 221, 1);
  padding: 20px 200px;
}


@media (max-width: 768px) {
  .commLoss-mail-div{
    padding: 20px 5px;
  }
}

.performance-monitoring-top-div {
  width: 75%;
  display: flex;
  gap: 10px;
  margin: 0 auto;
  justify-content: center;
}

@media (max-width: 820px) {
  .performance-monitoring-top-div {
    flex-direction: column;
  }
}

/* @media (min-width: 1024px) {
  .tw-card:nth-child(3n) {
      margin-right: 2rem; 
  }
  .tw-card:nth-child(9n) {
    margin-right: 2rem; 
}
} */


.no-scrollbars{
  scrollbar-width: thin;
  scrollbar-color: green transparent;
 
}

.tile {
  background-color: rgba(0, 0, 0, 0.2); /* Black with transparency */
  border-radius: 10px;
  backdrop-filter: blur(5px);
  margin: 10px;
  padding: 10px;
}

.tile.good {
  box-shadow: inset 0 0 10px 2px rgba(0, 255, 0, 0.7); /* Green inner shadow */
}

.tile.unhealthy {
  box-shadow: inset 0 0 10px 2px rgba(255, 255, 0, 0.7); /* Yellow inner shadow */
}

.tile.healthy {
  box-shadow: inset 0 0 10px 2px rgba(255, 0, 0, 0.7); /* Red inner shadow */
}

.fixed-first-column th:first-child,
.fixed-first-column td:first-child {
  position: sticky;
  left: 0;
  background-color: white;
  z-index: 2;  
}

.tw-dark .fixed-first-column th:first-child,
.tw-dark .fixed-first-column td:first-child {
  position: sticky;
  left: 0;
  background-color: black;
  z-index: 2;  
}
 
.dark-mode-option {
  background: black;
  color: white;
}


.tw-dark select option {
  background: black;
  color: white;
}


.bottomBar, .bottomBar * {
  user-select: none;
  -webkit-user-select: none; /* For Safari */
  -moz-user-select: none; /* For Firefox */
  -ms-user-select: none; /* For IE10+ */
  -webkit-touch-callout: none; /* For iOS Safari */
}


 
button[aria-label="Toggle View"] {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin: 10px;
}

button[aria-label="Toggle View"]:hover {
  color: #007bff; 
}


/* CustomMultiSelect.css */
.custom-multi-select {
  position: relative;
}

.dropdown {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
}

.placeholder {
  color: #999;
}

.dropdown-button {
  background: none;
  border: none;
  cursor: pointer;
}

.options-container {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  z-index: 1000;
  max-height: 150px;
  overflow-y: auto;
  padding: 8px;
}

.option {
  display: flex;
  align-items: center;
  padding: 4px 0;
}

.option input {
  margin-right: 8px;
}

/* implementing dark mode in map */
.tw-dark .leaflet-layer,
.tw-dark .leaflet-control-zoom-in,
.tw-dark .leaflet-control-zoom-out,
.tw-dark .leaflet-control-attribution {
  filter: invert(100%) hue-rotate(180deg) brightness(95%) contrast(90%);
}

.tw-dark .leaflet-container {
  background: transparent;
}

.tw-dark .leaflet-tooltip {
  background-image: linear-gradient(to bottom, #2A324B, #232A3B, #1D2230, #181B26, #141619);
  color: white;
}


/* maintenance image uploading */

.image-preview {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.image-item {
  position: relative;
}

.image-item img {
  display: block;
}

.image-item button {
  position: absolute;
  top: 5px;
  right: 5px;
  background: red;
  color: white;
  border: none;
  padding: 3px;
  cursor: pointer;
  border-radius: 10%;
}

.custom-radio[type="radio"] {
  position: relative !important;
  opacity: 1 !important;
  pointer-events: auto !important;
}


.custom-checkbox[type="checkbox"] {
  position: relative !important;
  opacity: 1 !important;
  pointer-events: auto !important;
}


.custom-radio-text[type="radio"] {
  display: none !important;
}


.custom-checkbox-text[type="checkbox"] {
  display: none !important;
}